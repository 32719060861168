import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeModule } from '../../../modules/primeng.module';

@Component({
  selector: 'app-g2g-dropdown',
  standalone: true,
  imports: [
    PrimeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './g2g-dropdown.component.html',
  styleUrl: './g2g-dropdown.component.scss'
})
export class G2gDropdownComponent {

  @Input('type') type: 'basic' | 'advanced' = 'basic';
  @Input('styleClass') styleClass: string = '';
  @Input('options') options: any[] = [];
  @Input('formname') form!: FormGroup;
  @Input('classes') classes: string = 'w-100';
  @Input('placeholder') placeholder: string = 'Enter Placeholder';
  @Input('formcontrolname') formcontrolname: string = '';
  @Input('displayLabel') displayLabel: string = '';
  @Input('value') value: string = '';
  @Input('filter') filter: boolean = false;
  @Input('filterBy') filterBy: string = '';
  @Input('showClear') showClear: boolean = false;

  hasError(formcontrolname: string, validation: string) {
    if(this.form && formcontrolname) {
      return this.form.get(formcontrolname)?.hasError(validation) && this.form.get(formcontrolname)?.touched;
    } else {
      return false;
    }
  }



}
